.container {

    position: relative;
    top: -42px;
    overflow: hidden;

    h4 {
        font-weight: 500;
        color: rgb(77, 77, 77);
        font-size: 16px;
        margin-bottom: 10px;
    }

    h3 {
        font-size: 28px;
    }

    p {
        font-weight: normal !important;
        line-height: 35px;
        font-size: 20px !important;

    }

    @media screen and (max-width:700px) {
        overflow-y: hidden;

    }
}