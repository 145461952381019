$primaryBlue: #008EFF;
$darkBlue: #3D83D2;
$darkerBlue: #09263f;
$lighterBlue: #e8f2ff;
$darkPurple: #0C0032;
$darkerPurple: #130c25;
$dark-gray: #444;
$light-gray: #8e8e8e;
$black: #000;

