@import '../../../sass/main.scss';

.market-monitoring-container {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    overflow: hidden;

    @media screen and (max-width: 800px) {
        height: auto;
        overflow-y: hidden;
    }
}

.market-monitoring-wrapper {
    height:auto;
    width: 900px;
    margin: 80px 0 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 800px) {
        width: 90%;
        height: auto;
        margin-top: 100px;
        margin-bottom: 100px;
    }
}

.market-monitoring-heading {
    line-height: 1.5;
    margin-bottom: 16px;
}

.market-monitoring-img img {
    width: 100%;
    box-shadow: 0px 2px 20px 1px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 950px) {
        width: 90%;
        height: auto;
        margin-top: 40px;
        margin-bottom: 40px;
    }
    @media screen and (max-width: 640px) {
        width: 80%;
    }
}

.market-monitoring-img svg {
    @media screen and (max-width: 640px) {
        width: 100%;
        height:250px;
   
    }
    @media screen and (min-width: 641px) and (max-width: 1007px) {
        width: 70%;
    
    }
}

.market-monitoring-text p {
    font-size: 20px;
    line-height: 1.5;

    @media screen and (max-width: 620px) {
        font-size: 18px;
    }

    @media screen and (max-width: 400px) {
        font-size: 16px;
    }
}