@import '../../../sass/main.scss';

.latest-posts-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 60px;
    width: 100%;

    @media screen and (max-width: 1450px) {
        grid-template-columns: repeat(3, 1fr);
        grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 950px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(3, 1fr);
        grid-gap: 40px;
    }
}

.latest-posts-wrapper {
    width: 420px;
    height: 360px;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: 0 10px 60px rgba(20, 32, 50, 0.075);

    @media screen and (max-width: 1060px) {
        margin: 30px 0 30px 0;
        align-self: center;
    }

    @media screen and (max-width: 470px) {
        width: 100%;
    }
    
}

.post-link {
    color: #000;
    text-decoration: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.post-img {
    height: 150px;
    width: 100%;
    border-radius: 6px 6px 0 0;
    object-fit: cover;
    align-self: center;
}

.post-title {
    display: flex;
    // height: 55px;
    padding-right: 5px;
    padding-left: 5px;
    margin-top: 16px;
    justify-content: center;
    text-align: center;

    h3 {
        line-height: 1.5;
        font-size: 20px;
        font-weight: 500;
    }
}

.post-description {
    margin-top: 16px;
    line-height: 1.3;
    color:  #666;
    padding: 0 24px 0 24px;
    text-align: left;
    font-size: 18px;
    max-height: 110px;

    @media screen and (max-width: 1420px) {
        overflow: hidden;
    }

    @media screen and (max-width: 600px) {
        font-size: 15px;
    }
    
}