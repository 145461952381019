@import '../../../sass/main.scss';

.sku-matching-container {
    height: 1150px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    background-color: $lighterBlue;
    opacity: 1;
    overflow: hidden;

    @media screen and (max-width: 920px) {
        height: auto;
    }
}

.sku-matching-wrapper {
    height: 960px;
    width: 900px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 920px) {
        width: 90%;
        height: auto;
        margin-top: 60px;
        margin-bottom: 60px;
    }
}

.sku-matching-heading {
    line-height: 1.5;

    @media screen and (max-width: 920px) {
        margin-bottom: 60px;
    }
}

.sku-matching-images {
    height: 570px;
    width: 900px;

    @media screen and (max-width: 920px) {
        width: 90%;
        height: auto;
    }
}

.sku-matching-image {
    width: 680px;
    height: auto;
    filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1));
    
}

.netshoes-three {
    margin-right: 170px;

    @media screen and (max-width: 920px) {
        width: 95%;
    }
}

.netshoes-four {
    margin-left: 170px;
    margin-top: -260px;

    @media screen and (max-width: 920px) {
        width: 95%;
        margin-left: 15%;
        margin-top: -20%;
    }
}

.sku-matching-text p {
    font-size: 20px;
    line-height: 1.5;

    @media screen and (max-width: 920px) {
        margin-top: 60px;
        margin-bottom: 60px;
    }

    @media screen and (max-width: 620px) {
        font-size: 18px;
    }

    @media screen and (max-width: 400px) {
        font-size: 16px;
    }
}