@import '../../../sass/main.scss';

.about-container {
    height: auto;
    width: 100%;
    background-color: $lighterBlue;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
    line-height: 1.5;
}

.about-wrapper {
    height: auto;
    width: 720px;
    margin: 80px 0 80px 0;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 780px) {
        width: 95%;
    }
}

.about-content {
    width: auto;
    text-align: center;
    height: auto;
}

.about-heading {
    margin-bottom: 20px;
    text-align: center;
}

.about-text p {
    font-size: 20px;
    line-height: 1.5;
    text-align: center;

    @media screen and (max-width: 620px) {
        font-size: 18px;
    }

    @media screen and (max-width: 400px) {
         font-size: 16px;
    }
}