@import '../../sass/main.scss';

.pricing-button button {
    height: 40px;
    width: 280px;
    margin-top: 20px;
    background-color: $primaryBlue;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border-radius: 6px;
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    text-align: center;
    
    &:hover {
        transition: all 0.2s ease-in-out; 
        background-color: $darkBlue;
    }

    @media screen and (max-width:400px) {
        width: 70vw;
        font-size: 16px;
    }
}