@import "./abstracts/functions";
@import "./abstracts/mixins";
@import "./abstracts/variables";
@import "./base/animation";
@import "./base/base";
@import "./base/typography";
@import "./base/utilities";
@import "./components/button";
@import "./components/Planos/BoxPlans";
@import "./components/Planos/SelectBox";
@import "./components/Sobre/FlipCard";
@import "./base/container";
@import "./base/wrapper";

*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}

html {
    // This defines what 1rem is.
    font-size: 100%;
}

body {
    box-sizing: border-box;
}