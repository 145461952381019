@keyframes downToUp {
    from {
        top: 80vh;
    }

    to {
        top: 0;
    }
}

@keyframes upToDown {
    from {
        top: 0;
    }
    to {
        top: 80vh
    }
}

@keyframes hideOnTop {
    from {
        top: 0vh
    }
    to {
        top: -200vh
    }
}
@keyframes hideOnBottom {
    from {
        top: 0vh
    }
    to {
        bottom: 200vh
    }
}



@keyframes showFromTop {
    from {
        top: -200vh
    }
    to {
        top: 0vh
    }
}