.container {
    &--solution {
        @include container;
        min-height: 100vh;
        height: auto;
        position: relative;
        animation: downToUp .5s;
        transition: all 0.5s;
        margin-bottom: 100px
      
    }
    &--solutionhidding{
        animation: upToDown;
        position: relative;
        transition: all 0.5s;

    }

    &--guided {
        @include container;
        min-height: 100vh;
        height: auto;
        margin-bottom: 100px;
    }

    &--values {
        height: auto;

        overflow: hidden;
        opacity: 1;

        position: relative;
    }

    &--timeline {
        @include solution-container();
        width: 100%
    }

    &--timeline-odd {
        @include solution-container();
        background-color: #f4f7ff;
        opacity: 1;
        border-radius: 10px;

    }
}



@media screen and (max-width: 640px) {
    .container {
        &--solution {
            height: auto;
            width: auto;
        }
        &--solution-2 {
            
        }

        &--values {
            height: auto;
        }

        &--guided {
            height: auto;
        }
    }
}

