@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@200;300;400;500;700&display=swap');
@import "./sass/main.scss";
* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    
    h3 {
      font-size: 32px;

      @media screen and (max-width: 960px) {
        font-size: 28px;
      }

      @media screen and (max-width: 420px) {
        font-size: 24px;
      }
    }
  }
  
