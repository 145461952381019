@import '../../../sass/main.scss';

.alerts-container {
    height: 550px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: #f4f7ff;
    opacity: 1;
    overflow: hidden;
    background-image: url('./bg.svg');
    overflow: hidden;
    background-repeat: no-repeat;

    @media screen and (max-width: 700px) {
        text-align: left;
        height: 700px;
    }

    .wrapper {
        height: 380px;
        width: 890px;
        display: flex;

        @media screen and (max-width: 960px) {
            width: 90%;
        }

        @media screen and (max-width: 700px) {
            flex-direction: column;
            align-items: center;
            height: auto;
        }

        .heading {
            line-height: 1.5;
            margin-bottom: 16px;
        }


        .content {
            display: flex;
            flex-direction: column;
            align-items: left;
            justify-content: center;

            @media screen and (max-width: 960px) {
                width: 90%;
            }
        }

        .text {
            width: 100%;

            p {
                font-size: 20px;
                line-height: 1.5;

                @media screen and (max-width: 620px) {
                    font-size: 18px;
                }

                @media screen and (max-width: 400px) {
                    font-size: 16px;
                }
            }
        }


        .image {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                height: 380px;
                width: auto;
                margin-right: 40px;

                @media screen and (max-width: 960px) {
                    width: 90%;
                    height: auto;
                }

                @media screen and (max-width: 700px) {
                    height: 90%;
                    width: 240px;
                    margin-right: 0;
                    margin-bottom: 20px;
                }
            }
        }



    }
}