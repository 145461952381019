@import '../../../sass/main.scss';

.reseller-main-container {
    height: 740px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    opacity: 1;
    background-color: #FFF;
    opacity: 1;
    background-image:url('./bg.svg');
    background-position-y: 150px;   
    background-repeat: repeat-x;    

}

.reseller-main-wrapper {
    height: 500px;
    width: 1160px;
    display: flex;
    margin: 10px 0 10px 0;

    @media screen and (max-width: 1190px) {
        width: 90%;
    }

    @media screen and (max-width: 880px) {
        flex-direction: column-reverse;
        margin: auto;
        align-items: center;
        text-align: center;
    }
}

.reseller-main-text {
    width: 610px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-right: 50px;
    color: #000;

    @media screen and (max-width: 880px) {
        padding: 0;
    }

    @media screen and (max-width: 620px) {
        width: 90%;
    }
}

.reseller-main-heading {
    display: flex;
    flex-direction: column;

    h1 {
        font-size: 45px;
        font-weight: bold;   
    }

    @media screen and (max-width: 880px) {
        h1 {
            font-size: 40px;
        }
    }

    @media screen and (max-width: 620px) {
        h1 {
            font-size: 36px;
        }
    }
}

.reseller-main-subheading {
    margin-top: 10px;

    p {
        font-size: 20px;
        font-weight: 300;   
    }

    @media screen and (max-width: 620px) {
        p {
            font-size: 18px;
        }
    }
}

.reseller-main-image {
    height: 500px;
    width: auto;

    @media screen and (max-width: 1190px) {
        width: 40vw;
    }

    @media screen and (max-width: 880px) {
        min-width: 300px;
        height: auto;
        margin-bottom: 20px;
    }
}
