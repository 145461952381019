@import '../../../sass/main.scss';

.reseller-roadmap-container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.reseller-roadmap-wrapper {
    width: 1145px;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 80px;
    margin-bottom: 80px;
    background-image: url('../../../assets/images/monitoramentorevendedores/reseller-roadmap-bg.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media screen and (max-width: 1160px) {
        width: 90%;
    }

    @media screen and (max-width: 510px) {
        background-image: url('../../../assets/images/monitoramentorevendedores/reseller-roadmap-bg-mobile.svg');
        background-repeat: no-repeat;
    }
}

.reseller-roadmap-heading {
    line-height: 1.5;
    margin-bottom: 100px;
    width: 780px;
    text-align: center;

    @media screen and (max-width: 800px) {
        width: 90vw;
    }
}

.reseller-roadmap-1 {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
    }
}

.reseller-roadmap-1-infos {
    width: 330px;
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 20px;

    @media screen and (max-width: 1170px) {
        width: 30vw;
    }

    @media screen and (max-width: 900px) {
        width: 90vw;
        margin-right: 0;
        text-align: center;
    }
}

.reseller-roadmap-number {
    height: 32px;
    width: auto;

    @media screen and (max-width: 900px) {
        margin-top: 16px;
    }
}

.reseller-roadmap-text {
    margin-top: 16px;
    font-size: 20px;
    line-height: 1.5;

    @media screen and (max-width: 620px) {
        font-size: 18px;
    }

    @media screen and (max-width: 400px) {
        font-size: 16px;
    }
}

.reseller-roadmap-1-images {
    height: 380px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1170px) {
        width: 60vw;
    }

    @media screen and (max-width: 900px) {
        width: 90vw;
        height: auto;
    }
}

.reseller-roadmap-1-print {
    filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1));
    height: auto;

    @media screen and (max-width: 1170px) {
        svg {
            width: 60vw;
            height: auto;
        }
    }

    @media screen and (max-width: 900px) {
        svg {
        width: 90vw;
        }
    }
}

.reseller-roadmap-1-char {
    margin-top: -90px;
    z-index: 1;

    svg {
        float: right;

        @media screen and (max-width: 1170px) {
            width: 20vw;
        }

        @media screen and (max-width: 900px) {
            width: 160px;
            height: auto;
        }
    }
}

.reseller-roadmap-2 {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 900px) {
        flex-direction: column;
        margin-top: 60px;
    }
}

.reseller-roadmap-2-infos {
    width: 330px;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 20px;

    @media screen and (max-width: 1170px) {
        width: 30vw;
    }

    @media screen and (max-width: 900px) {
        width: 90vw;
        margin-left: 0;
        text-align: center;
    }
}

.reseller-roadmap-2-images {
    height: 380px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1170px) {
        width: 60vw;
    }

    @media screen and (max-width: 900px) {
        width: 90vw;
        height: auto;
    }
}

.reseller-roadmap-2-print {
    filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1));
    height: auto;

    @media screen and (max-width: 1170px) {
        svg {
            width: 60vw;
            height: auto;
        }
    }

    @media screen and (max-width: 900px) {
        svg {
        width: 90vw;
        }
    }
}

.reseller-roadmap-2-char {
    margin-top: -90px;
    z-index: 1;

    svg {
        float: left;

        @media screen and (max-width: 1170px) {
            width: 20vw;
        }

        @media screen and (max-width: 900px) {
            width: 160px;
            height: auto;
        }
    }
}

.reseller-roadmap-3 {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 900px) {
        flex-direction: column-reverse;
        margin-top: 60px;
    }
}

.reseller-roadmap-3-infos {
    width: 330px;
    display: flex;
    flex-direction: column;
    text-align: right;
    margin-right: 20px;

    @media screen and (max-width: 1170px) {
        width: 30vw;
    }

    @media screen and (max-width: 900px) {
        width: 90vw;
        margin-right: 0;
        text-align: center;
    }
}

.reseller-roadmap-3-images {
    height: 380px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1170px) {
        width: 60vw;
    }

    @media screen and (max-width: 900px) {
        width: 90vw;
        height: auto;
    }
}

.reseller-roadmap-3-print {
    filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1));
    height: auto;

    img { 
        max-width: 790px;
    }
   

    @media screen and (max-width: 1170px) {
        img {
            width: 60vw;
            height: auto;
        }
    }

    @media screen and (max-width: 900px) {
        img {
        width: 90vw;
        }
    }
}

.reseller-roadmap-3-char {
    z-index: 1;

    svg {
        float: right;
        transform: translate(100px, -220px);

        @media screen and (max-width: 1170px) {
            width: 20vw;
        }

        @media screen and (max-width: 900px) {
            width: 160px;
            height: auto;
            transform: translate(0px, 0px);

        }
    }
}

.reseller-roadmap-4 {
    display: flex;
    flex-direction: row;

    @media screen and (max-width: 900px) {
        flex-direction: column;
        margin-top: 60px;
    }
}

.reseller-roadmap-4-infos {
    width: 470px;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 20px;

    @media screen and (max-width: 1170px) {
        width: 45vw;
    }

    @media screen and (max-width: 900px) {
        width: 90vw;
        margin-left: 0;
        text-align: center;
    }
}

.reseller-roadmap-4-images {
    height: 600px;
    max-width: 620px;
    display: flex;
    flex-direction: column;

    @media screen and (max-width: 1170px) {
        width: 50vw;
    }

    @media screen and (max-width: 900px) {
        width: 90vw;
        height: auto;
    }
}

.reseller-roadmap-4-print {
    filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1));
    height: auto;

    img {
        max-width: 620px;
    }
    

    @media screen and (max-width: 1170px) {
        img {
            width: 50vw;
            height: auto;
        }
    }

    @media screen and (max-width: 900px) {
        align-self: center;
        img {
        width: 60vw;
        }
    }
}

.reseller-roadmap-4-char {
    margin-top: -200px;
    z-index: 1;

    svg {
        float: left;
        transform: translate(0px, 150px);

        @media screen and (max-width: 1170px) {
            width: 20vw;
        }

        @media screen and (max-width: 900px) {
            width: 160px;
            height: auto;
            transform: translate(-10px, 50px);

        }
    }

    @media screen and (max-width: 900px) {
        margin-top: -140px;
    }
}
