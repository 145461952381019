.numbers-container {
    background-color: #fff;
    height: 15rem;
    justify-content: space-around;
    display: flex;
    // background-color: red;
}

.numbers-container-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 60%;
    justify-content: space-between;
    & div {
        margin: 2rem;
        & h3 {
            font-size: 3.5rem;
            color: #232323;
            width: 100%;
            text-align: center;
            letter-spacing: 5px;
          
        }
        & span {
            font-size: 18px;
            display: block;
            width: 100%;
            text-align: center;
            color: #727272;
            font-weight: 400;
            text-transform: uppercase;
        }
    }
}


@media screen and (min-width: 1008px) {
    .numbers-container-wrapper {
        width: auto;
    }
}

@media screen and (min-width: 641px) and (max-width: 1007px) {
    .numbers-container-wrapper {
        width: auto;
    }
}

@media screen and (max-width: 640px) {

    .numbers-container {
        height: 90vh;
        padding: 2rem 0;
    }

    .numbers-container-wrapper {
        flex-direction: column;
    }

    .numbers-item {
        margin: 2rem 0
    }
}
