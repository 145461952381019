@import '../../../sass/main.scss';

.marketplace-rules-container {
    height: 1180px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: left;
    background-color: #fff;
    overflow: hidden;

    @media screen and (max-width: 1240px) {
        height: 1280px;
    }

    @media screen and (max-width: 1000px) {
        height: 1380px;
    }

    @media screen and (max-width: 380px) {
        height: 1180px;
    }
}

.marketplace-rules-wrapper {
    height: 940px;
    width: 1090px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1240px) {
        width: 90%;
    }

    @media screen and (max-width: 380px) {
        height: 1000px;
    }
}

.marketplace-rules-heading {
    width: 800px;
    margin-bottom: 80px;
    line-height: 1.5;

    @media screen and (max-width: 1240px) {
        width: 90%;
    }

    @media screen and (max-width: 400px) {
        height: 180px;
        margin-bottom: -100px;
    }
}

.marketplace-rules-images {
    height: 790px;
    display: flex;
    flex-direction: column;

    
    @media screen and (max-width: 1240px) {
        width: 80%;
    }

    @media screen and (max-width: 900px) {
        width: 90%;
    }

}

.rules-row-one {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-left: 400px;
    margin-right: 50px;

    @media screen and (max-width: 1240px) {
        width: 95%;
        margin-left: 10%;
        margin-right: 2%;
    }

}

.rules-row-two {
    flex: 1;
    display: flex;
    flex-direction: row;
    margin-top: -200px;
    margin-left: 50px;

    @media screen and (max-width: 1240px) {
        width: 95%;
        margin-top: 0;
        margin-left: 0;
    }

    @media screen and (max-width: 400px) {
        margin-top: -200px;
    }

}

.rules-png {
    height: 370px;
    width: auto;
    filter: drop-shadow(0px 2px 20px rgba(0, 0, 0, 0.1));

    @media screen and (max-width: 1240px) {
        width: 90%;
        height: auto;
    }
}


.rules-svg {
    height: 290px;
    width: auto;

    @media screen and (max-width: 1240px) {
        width: 50%;
        height: auto;
    }
}

.marketplace-rules-girl {
    margin-left: -150px;
    margin-top: 115px;
    z-index: 1;
}

.marketplace-rules-boy {
    margin-top: 150px;
    margin-right: -100px;
    z-index: 1;
}

.marketplace-rules-text p {
    font-size: 20px;
    line-height: 1.5;
    margin-top: 80px;

    @media screen and (max-width: 920px) {
        margin-top: 60px;
    }

    @media screen and (max-width: 620px) {
        font-size: 18px;
    }

    @media screen and (max-width: 400px) {
        font-size: 16px;
    }
}
