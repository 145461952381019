@import '../../sass/main.scss';

.problems-button button {
    height: 40px;
    width: 380px;
    background-color: $primaryBlue;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border-radius: 6px;
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;

    &:hover {
        transition: all 0.2s ease-in-out;
        background-color: $darkBlue;
    }

    @media screen and (max-width:400px) {
        width: 80vw;
        font-size: 16px;
    }


    display: flex;
    align-items: center;
    justify-content: center;

    svg{
        margin-right:5px;
        width: 24px;
        height: 24px;
    }

}