@import '../../sass/main.scss';

.contact-form-container {
    height: auto;
    padding: 80px;
    width: 100%;
    background-color: $lighterBlue;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-form-wrapper {
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 540px) {
        width: 90vw !important;
    }
}

.contact-form-heading {
    text-align: center;
    margin-bottom: 10px;
}

.contact-form-subheading {
    text-align: center;
    margin-bottom: 40px;

    p {
        font-size: 20px;
        color: #444;

        @media screen and (max-width: 940px) {
            font-size: 18px;
        }
    }
}

.contact-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 430px) {
        width: 90%;
    }
}

.form-inputs-area {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 430px) {
        width: 90vw;
    }
}

.form-inputs {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    position: relative;
    &--label {
        position: absolute;
        top: 0;
        left: 0;
    }
    p {
        font-size: 12px;
        margin-top: 6px;
        margin-bottom: 6px;
        color: red;
    }
}

.form-label {
    margin-bottom: 15px;

    &__info {
        color: lightslategray;
        font-size: 16px;
        padding-left: 5px;
    }
}

.form-label-required {
    color: gray !important;
    transition: 3s !important;
    text-align: end;
    position: absolute;
    right: 16px;
    top: 35px;
    font-style: italic;
    &--disable {
        transition: 3s !important;
        text-align: end;
        position: absolute;
        right: 16px;
        top: 35px;
        font-style: italic;
        opacity: 0;        
    }
}

.opacity {
    opacity: 0;
}

.accept-privacy-policy {
    display: flex;
    align-items: center;
    flex-direction: row;
    font-size: 14px;

    input[type='checkbox'] {
        margin-right: 12px;
    }

    a {
        color: $primaryBlue !important;

        &:hover {
            text-decoration: underline !important;
        }
    }
}

.form-input {
    display: block;
    height: 36px;
    outline: none;
    border-radius: 8px;
    height:40px;
    width: 100%;
    border: none;
    padding: 6px;
    font-size: 16px;
    border:1px solid #e1e1e1;

    // border-bottom: 1px solid #000;
}

.success-message-wrapper {
    height: 360px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.success-message-image {
    width: 300px;
    height: auto;
}

.success-message-text {
    margin-top: 20px;
    text-align: center;
    color: #444;
    font-size: 18px;
}
