@import '../../../sass/main.scss';

.plans-form-container {
    height: auto;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 150px;
}

.plans-form-wrapper {
    height: 100%;
    width: 40vw;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 820px) {
        width: 90%;
    }
}

.plans-form-heading {
    text-align: center;
    margin-bottom: 10px;
}

.plans-form-subheading {
    text-align: center;
    margin-bottom: 40px;

    p {
        font-size: 20px;
        color: #444;

        @media screen and (max-width: 940px) {
            font-size: 18px;
        }
    }
}

.plans-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    width: 100%;

    @media screen and (max-width: 430px) {
        width: 90%;
    }
}

.plans-form-inputs-area {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;


    @media screen and (max-width: 430px) {
        width: 90%;
    }
}

.plans-form-inputs {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;

    p {
        font-size: 12px;
        margin-top: 6px;
        margin-bottom: 6px;
        color: red;
    }
}

.form-label {
    margin-bottom: 6px;
}

.plans-form-input {
    display: block;
    height: 36px;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    border: none;
    padding: 6px;
    font-size: 16px;
    background-color: $lighterBlue;
}

.plans-success-msg-wrapper {
    height: 360px;
    width: 300px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.plans-success-msg-image img {
    width: 300px;
    height: auto;
}

.plans-success-msg-text {
    margin-top: 20px;
    text-align: center;
    color: #444;
    font-size: 18px;
}