@import '../../../sass/main.scss';

.our-prices-container {
 
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;

    @media screen and (max-width: 700px) {
      
        height: 700px;

        button{
            width: 100%;
        }
    }
}

.our-prices-wrapper {
    padding: 40px 0px;
    width: 930px;
    display: flex;

    @media screen and (max-width: 960px) {
        width: 90%;
    }

    @media screen and (max-width: 700px) {
        flex-direction: column-reverse;
        height: auto;
        align-items: center;
    }
}

.our-prices-content {
    width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 960px) {
        width: 90%;
    }
}

.our-prices-heading {
    margin-bottom: 16px;
}

.our-prices-text {
    width: 100%;

    
    p {
        font-size: 20px;
        line-height: 1.5;

        @media screen and (max-width: 620px) {
            font-size: 18px;
        }

        @media screen and (max-width: 400px) {
            font-size: 16px;
        }

       
    }
}

.our-prices-image {
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
        margin-left: 40px;
        height: 400px;
        width: auto;

        @media screen and (max-width: 960px) {
            width: 90%;
            height: auto;
            margin-left: 0px;
        }

        @media screen and (max-width: 700px) {
            height: 80%;
            width: auto;
            margin-left: 0px;
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width: 700px) {
        height: 300px;
    }
}