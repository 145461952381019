.dot {
    background: black;
}

;

.carousel .control-dots .dot {
    background-color: black;
}


.carousel .slider-wrapper.axis-horizontal .slider .slide {
    scale: 1;
    margin-top: 20px;
    user-select: none;
    cursor: pointer;

    img {
        object-fit: contain;
        height: 100%;
        width: 100%;
        position: relative;

        @media screen and (max-width: 768px) {
            height: 40vh !important;
            object-fit: cover !important;
            object-position: -10px !important;
            box-shadow: none !important;
            border-radius: none !important;
            border-radius: 0px !important;

        }
    }
}



.carousel-root {
    margin-bottom: 20px;
    width: 80%;
    box-shadow: 0px 0px 8px 4px #e1e1e1;
    margin-top: 40px;


    @media screen and (max-width: 768px) {
        width: 100% !important;


    }

}