@import '../../../sass/main.scss';

.demo-form-container {
    height: auto;
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 150px;
}

.demo-form{
    width:100%;
}

.demo-form-label-required {
    color: gray !important;
    transition: 3s !important;
    text-align: end;
    position: absolute;
    right: 16px;
    top: 35px;
    font-style: italic;
    &--disable {
        transition: 3s !important;
        text-align: end;
        position: absolute;
        right: 16px;
        top: 35px;
        font-style: italic;
        opacity: 0;        
    }
}

.demo-form-wrapper {
    border:1px solid #e1e1e1;
    padding:40px;
    border-radius: 15px;
    width: 35vw;
    margin: 0px 0px 0px 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 10px 60px #e1e1e1;

    @media screen and (max-width: 820px) {
        width: 90%;
    }
}

.demo-form-heading {
    text-align: left;
    margin-bottom: 10px;
}

.demo-form-subheading {
    text-align: left;
    margin-bottom: 40px;

    p {
        font-size: 20px;
        color: #444;

        @media screen and (max-width: 940px) {
            font-size: 18px;
        }
    }
}

.plans-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;

    @media screen and (max-width: 430px) {
        width: 90%;
    }
}

.demo-form-inputs-area {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media screen and (max-width: 430px) {
        width: 100%;
    }
}

.demo-form-inputs {
    font-size: 18px;
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
    position:relative;

    p {
        font-size: 12px;
        margin-top: 6px;
        margin-bottom: 6px;
        color: red;
    }
}

.form-label {
    margin-bottom: 6px;
}

.demo-form-input {
    display: block;
    height: 36px;
    outline: none;
    border-radius: 2px;
    height: 40px;
    width: 100%;
    border: none;
    padding: 6px;
    font-size: 16px;
    background-color: $lighterBlue;
}

.demo-success-msg-wrapper {
    height: 360px;
    width: 300px;
    margin: 80px 0 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.demo-success-msg-image img {
    width: 300px;
    height: auto;
}

.demo-success-msg-text {
    margin-top: 20px;
    text-align: center;
    color: #444;
    font-size: 18px;
}