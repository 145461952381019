@import '../../../../sass/main.scss';

.input {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 4rem 2rem;

    &--heading {
        font-size: 20px;
        text-align: center;
        align-items: center;
        width: 100%;
    }
    &--boding {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 1rem;
        & input {
            width: 60%;
        }
    }
    &--value {
        @include heading;
        text-align: center;
    }
    &--contact {
        width: 100%;
        display: flex;
        justify-content: center;
    }

    &__handler {

        display: flex;
        width: 30px;
        height: 30px;
        transition: all.2s;

        &--increment {
            @include incrementDecrementButton;
        }
        &--decrement {            
            @include incrementDecrementButton;
        }
    }

    &__display {
        margin: 0 10px;
        font-size: 24px;
        font-weight: 500;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        border: solid 2px $primaryBlue;
        width: 4rem;
        height: 100%;
        border-radius: 4px;
        text-align: center;
        animation: downToUp .3s;

    }
}
