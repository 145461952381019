@import '../../../sass/main.scss';

.pricing-rules-container {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    overflow: hidden;
}

.pricing-rules-wrapper {
    height: 700px;
    margin: 80px 0 80px 0;
    width: 1300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1300px) {
        width: 100%;
        height: auto;
        margin-top: 100px;
        margin-bottom: 100px;
        width: 90%;

    }
}

.pricing-rules-heading {
    line-height: 1.5;
    margin-bottom: 20px;


}

.pricing-rules-image svg {
    height: 600px;
    width: auto;
    box-shadow: 0px 2px 20px 1px rgba(0, 0, 0, 0.1);

    @media screen and (max-width: 1300px) {
        width: 90%;
        height: auto;
        margin-top: 50px;
        margin-bottom: 50px;
    }
}

.pricing-rules-text {

    @media screen and (max-width: 620px) {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
    }

}

.pricing-rules-text p {
    font-size: 20px;
    line-height: 1.5;

    @media screen and (max-width: 620px) {
        font-size: 18px;

    }

    @media screen and (max-width: 400px) {
        font-size: 16px;
    }
}