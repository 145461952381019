@import '../../../sass/main.scss';

.pricing-faq-container {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.netshoes-faq {
    background-color: $lighterBlue;
}

.pricing-faq-wrapper {
    height: 780px;
    width: 830px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 850px) {
        width: 90%;
        min-height: 780px;
        height: auto;
        padding-top: 80px;
        padding-bottom: 80px;
    }
}

.netshoes-wrapper {
    height: 580px;
}

.pricing-faq-heading {
    text-align: center;
    margin-bottom: 60px;
}

.faq-area {
    display: flex;
    flex-direction: column;
    width: 830px;

    @media screen and (max-width: 850px) {
        width: 90%;
    }
}

.question-wrap {
    display: flex;
    border-radius: 6px;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    text-align: left;
    cursor: pointer;
    margin-bottom: 16px;

    h4 {
        padding: 20px;
        font-size: 20px;

        @media screen and (max-width: 620px) {
            font-size: 18px;
        }
    }

    i {
        margin-right: 22px; 
    }
}

.netshoes-question {
    background-color: #FFF;
    box-shadow: 0px 0px 2px 1px #d5d5d5;
}

.dropdown-answer {
    width: 100%;
    font-size: 18px;
    margin-bottom: 16px;
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: left;
    padding: 20px;
    line-height: 1.5;

    @media screen and (max-width: 620px) {
        font-size: 16px;
    }
}

