@import '../../../sass/main.scss';

.problems-container {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $lighterBlue;
    opacity: 1;
 
    @media screen and (max-width: 790px) {
        height: 1300px;
    }
}

.problems-wrapper {
    height: 560px;
    width: 880px;
    margin: 80px 0 80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 880px) {
        width: 100%;
    }

    @media screen and (max-width: 790px) {
        height: 1050px;
    }
}

.problems-heading {
    line-height: 1.5;
}

.problems-items-wrapper {
    width: 880px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    justify-items: center;

    @media screen and (max-width: 880px) {
        width: 100%;
    }

    @media screen and (max-width: 790px) {
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: 1fr;
        height: 960px;
    }
}

.problems-items {
    flex: 1;
    height: 300px;
    padding: 20px;
    // background-color: red;

    .problems-image{
        margin-bottom: 20px;
        height:200px;
        display: flex;
        align-items: center;
        justify-content: center;
        // background-color: blue;
    }
}

.problems-items-img svg {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;
}

.problems-items-text p {
    font-size: 18px;
    font-weight: 400;
    text-align: center;

    @media screen and (max-width: 400px) {
        font-size: 16px;
    }
}