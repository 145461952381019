@import '../../../sass/main.scss';

.main-container {
    height: 740px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #FFF;
    opacity: 1;
    background-image:url('./bg.svg');
    // background-size: 100%;
    background-position-y: -200px;   
    background-repeat: repeat-x;
    // background-position-x: 1300px;

    @media screen and (max-width: 880px) {
        height: auto;
     
        background-position-y: 205px;   
   
    }
}

.main-wrapper {
    height: 500px;
    width: 1160px;
    display: flex;
    margin: 10px 0 10px 0;
    

    @media screen and (max-width: 1190px) {
        width: 90%;
    }

    @media screen and (max-width: 880px) {
        flex-direction: column-reverse;
        margin: auto;
        align-items: center;
        text-align: center;
        height: auto;
        margin: 100px 0 100px 0;
    }
}

.main-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding-bottom: 100px;
    padding-right: 50px;
    color: #000;

    @media screen and (max-width: 880px) {
        padding: 0;
    }

    @media screen and (max-width: 620px) {
        width: 90%;
    }
}

.main-heading {
    display: flex;
    flex-direction: column;

    h1 {
        font-size: 45px;
        font-weight: bold;
    }

    @media screen and (max-width: 880px) {
        h1 {
            font-size: 40px;
        }
    }

    @media screen and (max-width: 620px) {
        h1 {
            font-size: 36px;
        }
    }
}

.dynamic-texts {
    font-size: 38px;
    font-weight: 300;
    margin-top: 10px;

    @media screen and (max-width: 1070px) {
        font-size: 32px;
    }

    @media screen and (max-width: 620px) {
        font-size: 28px;
    }

    @media screen and (max-width: 430px) {
        font-size: 20px !important;
    }
}

.main-subheading {
    margin-top: 10px;

    p {
        font-size: 20px;
        font-weight: 400;   
    }

    @media screen and (max-width: 620px) {
        p {
            font-size: 18px;
        }
    }
}


.main-image {
    height: 500px;
    width: auto;

    @media screen and (max-width: 1190px) {
        width: 40vw;
    }

    @media screen and (max-width: 880px) {
        min-width: 300px;
        height: auto;
        margin-bottom: 20px;
    }
}




