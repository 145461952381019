.flip-card {
    border-radius: 20px;
    transition: all 0.5s;
    text-align: center;
    background-color: #FFF;
    height: auto;
    flex:1;
    max-width: 375px;
    cursor: pointer;
    height: auto;
    box-shadow: 0 20px 10px rgb(230, 230, 230);
    border:1px solid #e1e1e1;
    text-align: left;


    &--header{
        padding: 20px;
    
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        font-weight: bold;

        
        svg{
            margin-bottom: 15px;
            color: $primaryBlue;
        }
    }

    &--content{
        padding: 20px;
        line-height: 25px;
        font-size: 16px;

    }

    &:hover{
        scale: 1.03;
    }


}



