.wrapper {
    &--solution {
        @include solution-wraper(row);
    }

    &--solution-odd {
        @include solution-wraper(row-reverse);
    }

    &--values {
        width: 100%;
        display: flex;
        justify-content: center;
        height: auto;
    }
}

@media screen and (max-width: 640px) {
    .wrapper {
        &--solution {
            padding: 0;
            width: 100%;
        }

        &--solution-odd {
            padding: 0;
        }
    }
}