@import '../../../sass/main.scss';

.pricing-problems-container {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    background-color: $lighterBlue;
    opacity: 1;
    padding: 40px


}

.pricing-problems-wrapper {
   

    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 700px) {
        height: auto;
        margin: 0px 0px 0px 0px;
        width: 90%;

    }




}

.pricing-problems-heading {
    line-height: 1.5;
    text-align: center;

    
    @media screen and (max-width: 700px) {
        margin-bottom: 40px;
     
    }
}

.pricing-problems-area {
    display: grid;
    grid-template-columns: repeat(4, 1fr);


    @media screen and (max-width: 1200px) {
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
    }

    @media screen and (max-width: 700px) {
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4, 1fr);
        width: 100%;
    }
}

.pricing-problems-items {
    flex: 1;
    height: 300px;
    padding: 20px;



    @media screen and (max-width:700px) {
        padding: 0px;
        width: 100%;
        height: auto;
        margin-bottom: 60px;


    }
}

.pricing-problems-img {
    text-align: center;

    svg {
        width: auto;
        height: 130px;
        margin-bottom: 10px;

    }

}

.pricing-problems-text {
    margin-bottom: 20px;
    margin-top: 10px;
    width: 250px;
  

    @media screen and (max-width:700px) {
        width: 100%;
    }

    p {
        font-size: 18px;
    }
}