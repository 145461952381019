$primaryBlue: #008EFF;
$darkBlue: #3D83D2;
$darkerBlue: #042E44;
$lighterBlue: #F0F5F8;

.dropdown-menu {
    background: #fff;
    width: fit-content;
    position: absolute;
    top: 80px;
    list-style: none;
    text-align: start;
    box-shadow: 0 30px 60px rgba(20, 32, 50, 0.1);

    .dropdown-info {
        padding: 18px;
        font-size: 14px;
        background-color: $lighterBlue;
        display: block; 
    }

    &.clicked {
        display:none;
    }
}

.dropdown-menu li {
    cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
        transition: all 0.2s ease;
        background-color: $darkBlue;
    }
}

.dropdown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #000;
    padding: 18px 22px 18px 22px;
    font-size: 16px;

    span {
        padding-left: 10px;
    }

    &:hover {
        color: #fff;
    }
}

.fa-caret-down {
    @media screen and (max-width: 1000px) {
        display: none;
    }
}


