@import '../../../sass/main.scss';

.your-performance-container {
    height: 550px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f7ff;
    opacity: 1;
    overflow: hidden;

    @media screen and (max-width: 700px) {
        text-align: left;
        height: 700px;
    }
}

.your-performance-wrapper {
    height: 380px;
    width: 890px;
    display: flex;

    @media screen and (max-width: 960px) {
        width: 90%;
    }

    @media screen and (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        height: auto;
    }
}

.your-performance-image {
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
        width: auto;
        height: 380px;
        margin-right: 40px;

        @media screen and (max-width: 960px) {
            width: 90%;
            height: auto;
        }

        @media screen and (max-width: 700px) {
            height: 90%;
            width: 240px;
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
}

.your-performance-content {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;

    @media screen and (max-width: 960px) {
        width: 90%;
    }
}

.your-performance-heading {
 
    margin-bottom: 16px;


}

.your-performance-text {
    width: 100%;

    p {
        font-size: 20px;
        line-height: 1.5;

        @media screen and (max-width: 620px) {
            font-size: 18px;
        }

        @media screen and (max-width: 400px) {
            font-size: 16px;
        }
    }
}