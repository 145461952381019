@import '../../../sass/main.scss';

.demo-container {

    display: flex;

    section {
        flex: 1;
        padding: 100px;
    }

    .watch-demo {
        padding: 20px 0px 20px 0px;

        .black-btn {
            border: 3px solid #000;

            &:hover {
                background-color: #000;
            }
        }


        .blue-btn {
            border: 3px solid $primaryBlue;
            background-color: $primaryBlue;
            color: #FFF;

            &:hover {
                background-color: $darkerBlue;
                border: 3px solid $darkerBlue;
            }
        }

        .watch-headline {
            margin-bottom: 20px;
            display: flex;
            align-items: center;

            h2 {
                flex: 1;
            }

            button {
                border: 0px;
                transition-duration: 0.2s;

                svg {
                    color: gray;
                }

                &:hover {
                    svg {
                        color: #000;
                    }
                }
            }
        }

        button {

            padding: 10px;
            font-weight: bold;
            font-size: 16px;
            background-color: #FFF;
            transition-duration: 0.2s;
            display: flex;
            align-items: center;

            svg {
                margin-right: 5px;
            }

            &:hover {

                color: #FFF;
            }
        }
    }

    .error {
        color: red;
        margin-bottom: 10px;
    }

}


.demo-info-container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $lighterBlue;
}

.demo-info-wrapper {

    height: auto;

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media screen and (max-width: 900px) {
        width: 90%;
    }
}

.demo-info-heading {
    margin-bottom: 20px;


}

.demo-info-subheading p {
    font-size: 18px;
    line-height: 1.5;
    margin-bottom: 20px;



    @media screen and (max-width: 620px) {
        font-size: 18px;
    }

    @media screen and (max-width: 400px) {
        font-size: 16px;
    }
}