@import '../../../sass/main.scss';

.plans-section-container {
 
    width: 100%;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;

    @media screen and (max-width: 700px) {
       
        height: 700px;

        button{
            width:100%;
        }
    }
}

.plans-section-wrapper {
    padding: 80px;
    width: 930px;
    display: flex;

    @media screen and (max-width: 960px) {
        width: 90%;
        padding: 0px;
    }

    @media screen and (max-width: 700px) {
        flex-direction: column;
        height: auto;
        align-items: center;
        padding: 0px;
    }
}

.plans-section-content {
    width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 960px) {
        width: 90%;
    }
}

.plans-section-heading {
    margin-bottom: 16px;
}

.plans-section-text {
    width: 100%;

    p {
        font-size: 20px;
        line-height: 1.5;

        @media screen and (max-width: 620px) {
            font-size: 18px;
        }

        @media screen and (max-width: 400px) {
            font-size: 16px;
        }
    }
}

.plans-section-image {
    display: flex;
    align-items: center;
    justify-content: center;
    
    svg {
        margin-right: 40px;
        height: 370px;
        width: auto;

        @media screen and (max-width: 960px) {
            width: 90%;
            height: auto;
            margin-right: 0px;
        }

        @media screen and (max-width: 700px) {
            height: 80%;
            width: auto;
            margin-left: 0;
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width: 700px) {
        height: 300px;
    }
}