@import '../../../sass/main.scss';

.values-content {
    display: block;
    width: 80%;
    height: auto;
    margin-bottom: 2rem;
 
    height: auto;
}

.values-cards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
 

    .flip-card{
        margin-right: 40px;
        margin-bottom: 20px;
    }

}

@media screen and (min-width: 641px) and (max-width: 1007px) {
    .values-container {
        height: 140vh;
        background-color: red;
    }

  
    .values-cards {
        margin: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .flip-card{
     
        margin-bottom: 20px;
    }



    .values-head {
        margin-top: 2rem;
    }
}

@media screen and (max-width: 640px) {
  

    .values-content {
        display: flex;
        flex-direction: column;
   
        width: 90%;
    }

    .values-cards {
        margin: auto;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .flip-card{
        margin-right: 0px !important;
        margin-bottom: 20px;
    }
}
