@mixin container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@mixin wrapper {
    width: 100%;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 900px) {
        margin: 0rem 0 2rem 0;
    }
}

@mixin heading {
    font-size: 32px;
    font-weight: 700;
}

@mixin subheading {
    font-size: 24px;
    color: $dark-gray;
    line-height: 1.5;
}

@mixin flip-card {
    position: absolute;
    font-size: 1.2rem;
    padding: 1rem;
    border-radius: 20px;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;
    height: 100%;
}

@mixin solution-container {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
@mixin solution-text($direction) {
    text-align: $direction;
    font-size: 20px;
    line-height: 1.5;

    @media screen and (max-width: 620px) {
        font-size: 18px;
        text-align: justify;
        padding: 0;
        margin-bottom: 20px;
    }

    @media screen and (max-width: 400px) {
        font-size: 16px;
    }
}
@mixin solution-heading($text-align) {
   
    text-align: $text-align;
}

@mixin solution-wraper($direction) {
    height: auto;

    width: 1320px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: $direction;

    @media screen and (max-width: 1340px) {
        width: 90%;
        height: auto;
    }

    @media screen and (max-width: 1250px) {
        flex-direction: column;
    }
}

@mixin incrementDecrementButton {
    width: 100% !important;
    text-align: center !important;
    justify-content: center !important;
    background-color: transparent;
    font-size: 1.5rem;
    border-radius: 4px;
    background-color: $primaryBlue;
    color: white;
    font-weight: 700;
    border: none;

    &:hover {
        background-color: $darkBlue;
        font-weight: 700;
        border-radius: 4px;
        color: white;
        border: none;
    }
    &:disabled {
        background-color: darkgrey;
    }
}
