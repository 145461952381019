@import '../../../../sass/main.scss';

.dynam-pricing-container {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f4f7ff;
    opacity: 1;
    overflow: hidden;
}

.dynam-pricing-wrapper {
    height: 440px;
    width: 1320px;
    margin: 80px 0 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1340px) {
        width: 90%;
        height: auto;
    }

    @media screen and (max-width: 1250px) {
        flex-direction: column-reverse;
    }
}

.dynam-pricing-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-right: 60px;

    a {
        text-decoration: none;
        justify-self: left;

        @media screen and (max-width: 1250px) {
            justify-self: center;
        }
    }

    @media screen and (max-width: 1340px) {
        width: 50%;
        margin-right: 40px;
    }

    @media screen and (max-width: 1250px) {
        width: 90%;
        text-align: center;
        margin-right: 0;
        margin-top: 20px;
        align-items: center;
        justify-content: center;
    }
}

.dynam-pricing-heading {
    margin-bottom: 20px;
    text-align: left;

    @media screen and (max-width: 1250px) {
        text-align: center;
    }
}

.dynam-pricing-text p {
    font-size: 20px;
    line-height: 1.5;
    text-align: left;

    @media screen and (max-width: 1250px) {
        text-align: center;
    }

    @media screen and (max-width: 620px) {
        font-size: 18px;
    }

    @media screen and (max-width: 400px) {
        font-size: 16px;
    }
}

.dynam-pricing-cards {
    display: grid;
    grid-gap: 20px;
    margin-top: 20px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(3, 1fr);

    @media screen and (max-width: 1340px) {
        height: auto;
    }

    @media screen and (max-width: 1250px) {
        width: 100%;
        justify-self: center;
    }

    @media screen and (max-width: 800px) {
        grid-gap: 20px;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}



.dynam-pricing-card {
    width: -moz-fit-content;
    width: fit-content;

    border-radius: 6px;
    background-color: #fff;
    text-decoration: none;
    border-radius: 6px;
    background-color: #FFF;
    border:1px solid #e1e1e1;
    // box-shadow: 0px 0px 2px 1px rgb(224, 224, 224);
    display: flex;
    align-items: center;
    justify-content: center;
    justify-self: right;
    padding: 10px 20px;

    &:hover {
        transition: all 0.2s ease-in-out;
        background-color: #dceeff;
    }

    @media screen and (max-width: 1250px) {
        justify-self: center;
    }
}

.dynam-pricing-icon {
    margin-right: 20px;
    color: $primaryBlue;
}

.dynam-pricing-span {
    color: #000;
    font-size: 18px;
    text-align: center;
    font-weight: 500;

    @media screen and (max-width: 620px) {
        font-size: 16px;
    }

    @media screen and (max-width: 400px) {
        font-size: 14px;
    }
}

.dynam-pricing-image {
    width: 640px;
    height: auto;

    @media screen and (max-width: 1340px) {
        width: 100%;
        height: auto;
    }
}