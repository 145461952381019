.monitoring-prices-video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 80vh;
    background-color: #FFF;
    flex-direction: column;

    h3 {
        margin-bottom: 40px;
    }

    iframe {
        height: 310px;
        width: 550px;
        border-radius: 10px;
        @media screen and (max-width: 620px) {
            height: auto;
            width: auto;
        }
    }

}