@import '../../../sass/main.scss';

.cases-container {
    height: auto;
    width: 100%;
    background-color: #d8e9ff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    line-height: 1.5;
    color:#121212;
  
}

.cases-wrapper {
    width: 900px;
    margin: 60px 0 60px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;

    @media screen and (max-width: 1050px) {
        width: 600px;
    }

    @media screen and (max-width: 740px) {
        width: 85%;
    }

    .black-btn {
        border: 3px solid #000;

        &:hover {
            background-color: #000;
        }
    }

    button {

        padding: 10px;
        font-weight: bold;
        font-size: 16px;
        background-color: transparent;
        transition-duration: 0.2s;
        display: flex;
        align-items: center;

        svg {
            margin-right: 5px;
        }

        &:hover {

            color: #FFF;
        }
    }

}

.cases-heading {
    margin-bottom: 20px;
    text-align: left;
}

.cases-text p {
    font-size: 20px;
    line-height: 1.5;
    text-align: left;
    margin-bottom:20px;

    @media screen and (max-width: 620px) {
        font-size: 18px;
    }

    @media screen and (max-width: 400px) {
        font-size: 16px;
    }
}