@import '../../../../sass/main.scss';

.price-monit-container {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.price-monit-wrapper {
    height: 440px;
    width: 1320px;
    margin: 80px 0 80px 0;
    display: flex; 
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1340px){
        width: 90%;
        height: auto;
    }

    @media screen and (max-width: 1250px) {
        flex-direction: column;
    }
}

.price-monit-image {
    width: 600px;
    height: auto;

    @media screen and (max-width: 1340px){
        width: 100%;
        height: auto;
    }
}

.price-monit-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 60px;

    a {
        text-decoration: none;
        margin: auto;
    }

    @media screen and (max-width: 1340px) {
        width: 50%;
        margin-left: 40px;
    }

    @media screen and (max-width: 1250px) {
        width: 90%;
        text-align: center;
        margin-left: 0;
        margin-top: 20px;
        align-items: center;
        justify-content: center;
    }
}

.price-monit-heading {
    margin-bottom: 20px;
}

.price-monit-text p {
    font-size: 20px;
    line-height: 1.5;

    @media screen and (max-width: 620px) {
        font-size: 18px;
    }

    @media screen and (max-width: 400px) {
         font-size: 16px;
    }
}


.price-monit-cards {
    display: grid;
    grid-gap: 40px;
    max-width: 600px;
    margin-top: 20px;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);

    @media screen and (max-width: 1340px){
        width: 90%;
        height: auto;
    }

    @media screen and (max-width: 1250px) {
        width: 100%;
        justify-self: center;
    }

    @media screen and (max-width: 800px) {
        grid-gap: 20px;
    }

    @media screen and (max-width: 500px) {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(4, 1fr);
        width: 100%;
    }
}

.price-monit-card {
    
    
    text-decoration: none;
    border-radius: 6px;
    background-color: #FFF;
    box-shadow: 0px 0px 2px 1px #d5d5d5;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    width: 280px;
    padding: 20px 0px;

    &:hover {
        transition: all 0.2s ease-in-out;
        background-color: #dceeff; 
    }

    @media screen and (max-width: 650px) {
        width: 100%;
        height: 80px;
    }

    @media screen and (max-width: 500px) {
        width: 280px;
        height: 100px;
        justify-self: center;
    }
}

.price-monit-icon {

    color: $primaryBlue;
    margin-right: 20px;

    @media screen and (max-width: 650px) {
        img {
            height: 40px;
            width: 40px;
            margin-right: 0;
            margin-left: 6px;
        }
    }

    @media screen and (max-width: 500px) {
        img {
            height: 50px;
            width: 50px;
            margin-right: 10px; 
            margin-left: 0;
        }
    }
}

.price-monit-span {
    color: #000;
    text-decoration: none;
    font-size: 18px;
    max-width: 140px;
    text-align: center;
    font-weight: 500;
    flex:1;

    @media screen and (max-width: 620px) {
        font-size: 16px;
    }

    @media screen and (max-width: 400px) {
         font-size: 14px;
    }
}



