@import '../../../sass/main.scss';

.innovative-solutions-container {
    height: 550px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $lighterBlue;
    opacity: 1;

    @media screen and (max-width: 700px) {
        
        height: 700px;
     

    }
}

.innovative-solutions-wrapper {
    height: 380px;
    width: 890px;
    display: flex;

    @media screen and (max-width: 960px) {
        width: 90%;
    }

    @media screen and (max-width: 700px) {
        flex-direction: column;
        align-items: center;
        height: auto;
    }
}

.innovative-solutions-image {
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
        width: 350px;
        height: 380px;
        margin-right: 40px;

        @media screen and (max-width: 960px) {
            width: 90%;
            height: auto;
        }

        @media screen and (max-width: 700px) {
            height: 260px;
            width: auto;
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
}

.innovative-solutions-content {
    display: flex;
    flex-direction: column;
    align-items: left;
    justify-content: center;

    @media screen and (max-width: 960px) {
        width: 90%;
    }
}

.innovative-solutions-heading {
    text-align: left;
    margin-bottom: 16px;


}

.innovative-solutions-text {
    width: 100%;

    p {
        font-size: 20px;
        line-height: 1.5;

        @media screen and (max-width: 620px) {
            font-size: 18px;
        }

        @media screen and (max-width: 400px) {
            font-size: 16px;
        }
    }
}

.innovative-solutions-button button {
    height: 40px;
    width: 280px;
    margin-top: 20px;
    background-color: $primaryBlue;
    cursor: pointer;
    color: #fff;
    font-size: 18px;
    font-weight: 500;
    border-radius: 6px;
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;
    text-align: center;

    &:hover {
        transition: all 0.2s ease-in-out;
        background-color: $darkBlue;
    }

    @media screen and (max-width:700px) {
        width: 100%;
        font-size: 16px;
    }
}