@import '../../../sass/main.scss';

.pricing-plans-container {
    padding: 80px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: left;
    background-color: #f4f7ff;
    opacity: 1;
   
    @media screen and (max-width: 700px) {
        padding: 0px;
        height: 700px;

        
        button{
            width:100%;
        }
    }

    
}

.pricing-plans-wrapper {
 
    width: 930px;
    display: flex;

    @media screen and (max-width: 960px) {
        width: 90%;
    }

    @media screen and (max-width: 700px) {
        flex-direction: column;
        height: auto;
        align-items: center;
    }
}

.pricing-plans-content {
    width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 960px) {
        width: 90%;
    }
}

.pricing-plans-heading {
    margin-bottom: 16px;
}

.pricing-plans-text {
    width: 100%;

    p {
        font-size: 20px;
        line-height: 1.5;

        @media screen and (max-width: 620px) {
            font-size: 18px;
        }

        @media screen and (max-width: 400px) {
            font-size: 16px;
        }
    }
}

.pricing-plans-image {
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
        margin-right: 40px;
        height: 370px;
        width: auto;

        @media screen and (max-width: 960px) {
            width: 90%;
            height: auto;
            margin-right: 0px;
        }

        @media screen and (max-width: 700px) {
            height: 80%;
            width: auto;
            margin-left: 0;
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width: 700px) {
        height: 300px;
    }
}