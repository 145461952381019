.video-modal{
    transition-duration:0.1s;
    background-color: #000;
    width:70vw;
    height:80vh;

    position: fixed;
    left:50%;
    top:50%;
    margin-left:-35vw;
    margin-top:-40vh;
    z-index: 1001;
    box-shadow: 0px 0px 20px 5px rgb(61, 61, 61);

}

.video-modal-overlay{
    transition-duration:0.1s;
    background-color: rgba(38, 38, 38, 0.482);
    width: 100%;
    height: 100%;
    position: fixed;
    left:0px;
    top:0px;
    z-index: 1000;
}