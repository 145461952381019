@import '../../../sass/main.scss';

.reseller-problems-container {
    height: auto;
    width: 100%;
    background-color: $lighterBlue;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;

    opacity: 1;
}

.reseller-problems-wrapper {
    width: 600px;
    height: 550px;
    margin: 80px 0 80px 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 1200px) {
        height: 1000px;
    }

    @media screen and (max-width: 600px) {
        height: 1000px;
    }

    @media screen and (max-width: 400px) {
        height: 1000px;
    }

}

.reseller-problems-heading {
    line-height: 1.5;
}

.reseller-problems-area {
    width: 800px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    align-items: center;
    justify-items: center;

    @media screen and (max-width: 880px) {
        width: 100%;

    }

    @media screen and (max-width: 790px) {
        grid-template-rows: repeat(2, 1fr);
        grid-template-columns: 1fr;
        width: 100%;
    }
}

.reseller-problems-items {
    flex: 1;
    height: 300px;
    padding: 20px;


    @media screen and (max-width: 768px) {
        height: 220px;
    }


    .reseller-problems-img {
        margin-bottom: 20px;
        height: 120px;
        display: flex;
        align-items: center;
        justify-content: center;



    }
}

.reseller-problems-img svg {
    width: 100%;
    height: 100%;
    margin-bottom: 10px;

}

.reseller-problems-items-text p {
    font-size: 18px;
    font-weight: 400;
    text-align: center;

    @media screen and (max-width: 400px) {
        font-size: 16px;
    }
}