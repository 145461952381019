@import "../../../../sass/main.scss";

.isClosing {
    animation: upToDown .5s;
    position: relative;
}

.solution-main-wrapper {
    @include wrapper;
}

.solution-close {
    position: fixed;
    font-weight: 900;
    font-size: 32px;
    margin: 1rem;
    border: solid 2px $black;
    border-radius: 100px;
    height: 50px;
    width: 50px;
    background-color: white;
    color: white;
    right: 0;
    top: 80px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    z-index: 999;
    & div { 
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
        line-height: 100%
    }
    &:hover{
        border: solid 2px black;
        background-color: $primaryBlue;
    }
}