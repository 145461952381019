#privacy-container{
    
    background-color: #FFF;
    height:auto;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    min-height: 100vh;
    padding: 150px;

    h1, h3{
        margin-bottom: 24px;
      
    }

    h3{
        font-size: 24px;
        margin-top:24px;
    }

    p{
        font-size: 18px;
        margin-bottom: 24px;
        line-height: 30px;
    }
}