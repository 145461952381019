@import '../../sass/main.scss';

.not-found-container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
}

.not-found-wrapper {
    margin: 80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .not-found-image {
        width: 100%;
        max-width: 460px;
        height: auto;
    }

    .not-found-message {
        margin-bottom: 40px;

        h1 {
            font-size: 32px;
            text-align: center;
            width: 100%;

            @media screen and (max-width: 460px) {
                font-size: 26px;
            }
        }

        h2 {
            font-size: 26px;
            text-align: center;
            width: 100%;

            @media screen and (max-width: 460px) {
                font-size: 20px;
            }
        }
    }

    .not-found-links {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;
        width: 90%;
        max-width: 800px;

        .not-found-link {
            display: flex;
            flex-direction: column;

            a {
                margin-bottom: 8px;
                text-decoration: none;
                font-size: 18px;
                font-weight: 500;
                color: $primaryBlue;

                &:hover {
                    transition: all 0.2s ease-in-out;
                    color: $darkBlue; 
                }

                @media screen and (max-width: 460px) {
                    font-size: 16px;
                }
            }
        }

        @media screen and (max-width: 460px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: auto;

        }
    }
}