.heading-primary {
    &--main {
    }
    &--sub {
    }
}
.heading-secondary {
    margin-top: 2rem;
    margin-bottom: 2rem;

    &--main {
        text-align: center;
    }
    &--main-left {
        & h3 {
            text-align: left;
        }
    }
    &--main-right {
        & h3 {
            text-align: left;
        }
    }
    &--sub {
        font-size: 1.5rem;
        color: $dark-gray;
        line-height: 1.5;
        margin-bottom: 2rem;
        text-align: center;
    }
    &--sub-left {
    }
    &--sub-right {
    }
}

.heading-tertiary {
    &--main {
        
            
        font-size: 20px;
        font-weight: 500;
        
    
    }
    &--sub {
        font-size: 18px;
        color: $light-gray;
        // padding-top: 0.2rem;
        line-height: 28px;
    }
}

@media screen and(max-width: 1250px) {
}

@media (min-width: 641px) and (max-width: 1007px) {
}

@media screen and (max-width: 640px) {
    .heading-secondary {
        &--main {
        }
        &--sub {
            font-size: 1.2rem;
        }
    }
    .heading-tertiary {
        &--main {
            margin-bottom: 4px;
            font-size: 1.1rem;
        }
        &--sub {
            font-size: 1rem;
            line-height: 25px;
        }
    }
}
