@import '../../../sass/main.scss';

.guided-main-wrapper {
    @include wrapper;
}

.isUping {
    animation: hideOnTop .5s;
    position: relative;
}

.hideBottom {
    animation: hideOnBottom .5s;
    position: relative;
}

.showInUp {
    animation: showFromTop .5s;
    position: relative;
}

.question {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: center;

    &--content {
        cursor: pointer;
        border-radius: 10px;
        width: 500px;
        height: 400px;
        border: 1px solid #e1e1e1;
        box-shadow: 0px 0px 10px 20px rgb(223, 223, 223);
        padding: 1rem;
        transition: all 0.2s;

        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        & p {
            text-align: center;
            font-weight: 700;
            font-size: 24px;
        }
    }
}

@media screen and (max-width: 1250px) {
    .guided-main-container {
        height: auto;
    }

    .question {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 1rem;
    }

    .question--content {
        width: 70%;
        margin-bottom: 2rem;
    }
}

@media screen and (max-width: 620px) {

    .solution {
        svg {
            display: none;
        }
    }

}

@media screen and (max-width: 400px) {
    .question {
        display: flex;
        flex-direction: column;
    }
}

;