
.solution {
    border: 1px solid rgba($light-gray, .5);
    border-radius: 10px;
    box-shadow:0px 0px 20px 4px #e1e1e1;


    &--1{
        width:60vw;

    }
    &--2 {
        width:100%;
        transition: all .3s;
      

        @media screen and (max-width: 635px) {
            scale: 1;
            &:hover{
                scale: 1;

                box-shadow:0px 0px 8px 4px #e1e1e1;

            }
        }

    }
    &__heading {
        padding: 1rem;
        text-align: center;
        font-size: 24px;
        line-height: 3rem;
        font-weight: 500;
    }

    &__boding {

   
        &--row {

            &--icon{
                display:flex;
                align-items: center;
                justify-content: center;
                color:$primaryBlue;
                padding:0px 20px;
                margin-right: 20px;
                svg{
                    width:32px;
                    height:32px;
                }
            }
            
            padding: 20px 20px;
            border-bottom: 1px solid rgba($light-gray, .5);
            display:flex;
            align-items: center;
            

            &--center {
                display: flex; 
                align-Items: center; 
                justify-Content: center;  
                padding: 20px 20px;


                &__button {
                    font-Size: 16; 
                    display: flex; 
                    align-Items: center; 
                    & svg {
                        margin-right: 5px;
                    }
                }
            }   
            
            
            &:first-child {
            
                border-top: 1px solid rgba($light-gray, .3);
            }
        }
       
    }
}

.solution {
    &--2{

    }
}
@media screen and (max-width: 400) {
    .solution {
        margin: 1rem;
    }
}

@media screen and (max-width: 640px) {
    .solution {
        width: 100%;
        &__boding{
            &--row {
                padding: 10px 10px;
            }
            &--row--icon{
                margin: 0;
                padding: 0 8px;
            }
        }
    }
}

@media screen and (min-width: 641px) and (max-width: 1007px) {
    .solution {
        width: 80%;
    }
}

@media screen and (min-width: 1008px) {
}
