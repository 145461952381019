@import '../../../sass/main.scss';

.support-team-container {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #0c80df;
    opacity: 1;
    background-image:  radial-gradient(#559dea 2px, transparent 2px), radial-gradient(#559dea 2px, #0c80df 2px);
    background-size: 80px 80px;
    background-position: 0 0,40px 40px;
    overflow: hidden;
    color:#FFF;
}

.support-team-wrapper {
    height: auto;
    width: 800px;
    margin: 80px 0 80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 820px) {
        width: 90vw;
    }
}

.support-team-image {
    width: 600px;
    height: auto;
    margin-bottom: 20px;

    @media screen and (max-width: 620px) {
        width: 90vw;
    }
}

.support-team-heading {
    margin-bottom: 20px;
    text-align: center;
}

.support-team-text p {
    font-size: 20px;
    line-height: 1.5;
    text-align: center;

    @media screen and (max-width: 620px) {
        font-size: 18px;
    }

    @media screen and (max-width: 400px) {
         font-size: 16px;
    }
}

