@import '../../../sass/main.scss';

.blog-container {
    height: auto;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.blog-wrapper {
    height: auto;
    max-width: 1400px;
    margin: 80px 0 80px 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 1420px) {
        width: 95%;
    }
}

.posts-wrapper {
    height: auto;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.blog-title {
    margin-bottom: 60px;
    text-align: center;
}

.blog-button {
    background-color: $darkerBlue;
    height: 40px;
    line-height: 40px;
    padding: 0 10px 0 10px;
    margin-top: 60px;
    width: 190px;
    border-radius: 6px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    outline: none;
    border: none;
    transition: all 0.2s ease-in-out;

    a {
        color: #fff;
        text-decoration: none;
    }
    
    &:hover {
        transition: all 0.2s ease-in-out;
        color: #fff; 
        background-color: $primaryBlue;
    }
}

