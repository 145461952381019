@import '../../../sass/main.scss';

.about-container {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D8E9FF;
    opacity: 1;
    overflow: hidden;
}

.about-wrapper {
    height: 440px;
    width: 1320px;
    margin: 80px 0 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1340px) {
        width: 90%;
        height: auto;
    }

    @media screen and (max-width: 1250px) {
        flex-direction: column-reverse;
        width: 100%;
    }
}

.about-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-right: 60px;

    a {
        text-decoration: none;
        justify-self: left;

        @media screen and (max-width: 1250px) {
            justify-self: center;
        }
    }

    @media screen and (max-width: 1340px) {
        width: 50%;
        margin-right: 40px;
    }

    @media screen and (max-width: 1250px) {
        width: 100%;
        text-align: center;
        margin-right: 0;
        margin-top: 20px;
        align-items: center;
        justify-content: center;
        padding: 1rem;
    }
}

.about-heading {
    margin-bottom: 20px;
    text-align: left;

    @media screen and (max-width: 1250px) {
        text-align: center;
    }
}

.about-text p {
    font-size: 20px;
    line-height: 1.5;
    text-align: left;

    @media screen and (max-width: 1250px) {
        text-align: center;
    }

    @media screen and (max-width: 620px) {
        font-size: 18px;
    }

    @media screen and (max-width: 400px) {
        font-size: 20px;
        text-align: justify;
    }
}

.about-image {
    width: 600px;
    height: auto;

    @media screen and (max-width: 1340px) {
        width: 100%;
        height: auto;
    }
}