.box {
    cursor: pointer;
    border-radius: 10px;
    width: 400px;
    height: 400px;
    border: 1px solid #e1e1e1;
    box-shadow: 0 20px 10px rgb(236, 236, 236);
    padding: 1rem;
    transition: all 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    scale: 0.75;


    &--paragraph {
        text-align: center;
        font-weight: 500;
        font-size: 24px;
    }

    &--icon {
        clip-path: polygon(25% 0%,
                75% 0%,
                100% 50%,
                75% 100%,
                25% 100%,
                0% 50%);
        height: 250px;
        width: 250px;
        position: relative;
    }

    &:hover {
        scale: 1
    }
}


@media screen and (max-width: 640px) {
    .box {
        width: auto;
        height: auto;
        margin-bottom: 2.5rem;
        scale: 0.9;
        &:hover {
            scale: 0.9
        }
    }
}

@media screen and (min-width:641px) and (max-width: 1007px) {
    .box {

        width: 100%;
        margin: 3rem 4rem;
    }
}