@import '../../../sass/main.scss';

.reseller-plans-container {
    padding: 40px 0px;
    width: 100%;
    background-color: $lighterBlue;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: right;

    opacity: 1;
  
    @media screen and (max-width: 700px) {
        text-align: center;
        height: 700px;
    }
}

.reseller-plans-wrapper {
   
    width: 930px;
    display: flex;

    @media screen and (max-width: 960px) {
        width: 90%;
    }

    @media screen and (max-width: 700px) {
        flex-direction: column-reverse;
        height: auto;
        align-items: center;
    }
}

.reseller-plans-content {
    width: 550px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 960px) {
        width: 90%;
    }
}

.reseller-plans-heading {
    margin-bottom: 16px;
}

.reseller-plans-text {
    width: 100%;

    p {
        font-size: 20px;
        line-height: 1.5;

        @media screen and (max-width: 620px) {
            font-size: 18px;
        }

        @media screen and (max-width: 400px) {
            font-size: 16px;
        }
    }
}

.reseller-plans-image {
    display: flex;
    align-items: center;
    justify-content: center;
    
    img {
        margin-left: 40px;
        height: 320px;
        width: auto;

        @media screen and (max-width: 960px) {
            width: 90%;
            height: auto;
        }

        @media screen and (max-width: 700px) {
            height: 80%;
            width: auto;
            margin-left: 0;
            margin-bottom: 20px;
        }
    }

    @media screen and (max-width: 700px) {
        height: 300px;
    }
}