@import '../../sass/main.scss';
@font-face {
    font-family: 'logo-font';
    src: url(../../fonts/logo-font.ttf);
}

.footer-container {
    height: auto;
    width: 100%;
    background-color: $darkerBlue;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.footer-wrapper {
    width: 1000px;

    height: auto;
    margin: 80px auto 80px auto;
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 20px;

    @media screen and (max-width: 700px) {
        width: 90%;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: repeat(2, 1fr);
        display: flex;
        flex-direction: column;
    }
}

.footer-items {
    display: flex;
    flex-direction: column;
    align-items: left;
}

.footer-items-title h4 {
    justify-content: left;
    margin-bottom: 16px;
    font-size: 18px;
}

.footer-items-links a {
    display: block;
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    padding-bottom: 12px;
    font-weight: 300;
}

.footer-bottom {
    display: grid;
    grid-template-columns: 1fr 2fr 1fr;
    align-items: center;
    bottom: 20px;
    margin-bottom: 20px;


    @media screen and (max-width: 800px) {
        width: 90%;
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: 1fr 2fr 1fr;
        text-align: center;
    }
}

.footer-info {
    display: flex;
    /* grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px; */
    flex-direction: column;
    color: #fff;
    text-decoration: none;
    font-weight: 300;
    justify-content: space-between;
    @media screen and (max-width: 800px) {
        margin-bottom: 1rem;
    }
}

.footer-address {
    & ul {
        & li {
            list-style: none;
            padding-bottom: 12px;
        }
        @media screen and (max-width: 800px) {
            width: 90%;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }
    }
}
.footer-contact {
    & ul {
        margin-bottom: 12px;
        & li {
            list-style: none;
            padding-bottom: 12px;
        }
        @media screen and (max-width: 800px) {
            width: 90%;
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(2, 1fr);
        }
    }
}


.footer-logo a {
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: center;
    font-size: 20px;
    
    span {
        font-family: 'logo-font';
    }

    img {
        height: 24px;
    }

    .blue {
        color: $primaryBlue;
    }

    @media screen and (max-width: 800px) {
        justify-content: center;
    }
}

.footer-copyright {
    font-size: 14px;
    font-weight: 300;
}

.footer-icons a {
    color: #fff;
    text-decoration: none;
    margin-left: 20px;
}

@media screen and (max-width: 1000px) {
    .footer-wrapper {
        width: auto;
        padding: 0rem 1rem;
    }
}

@media screen and (max-width: 620px) {}

@media screen and (max-width: 400px) {}