@import '../../../sass/main.scss';


.fill-email{
    display: flex;
    flex-direction: column;

    a{
        color: $primaryBlue
    }

    .field{
        display: flex;
        margin-top:15px;
        margin-bottom: 15px;

        input{
            width:100%;
            padding: 4px;
            font-size: 16px;
            font-weight: bold;
        }

    }
}