@import "../../../../sass/main.scss";

.isClosing {
    animation: upToDown .5s;
}

.solutions {

    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 635px) {
        flex-direction: column;
        width: 100%;
        margin-Top: 0px;

    }
}

.solution-netshoes {
    scale: 0.75;
    height: auto !important;

    @media screen and (max-width: 635px) {

        &:first-child {
            margin-bottom: 4rem;
        }
    }
}

.solution-price {
    display: flex;
    flex-direction: column;

    & h4 {
        font-weight: 400;
        line-height: 28px;

    }

    & span {
        font-weight: 500;
        font-size: 24px;
        line-height: 28px
    }
}

.solutions-wrapper {

    display: flex;
    flex-Direction: row;
    justify-Content: center;
    width: 80%;
    margin-Top: -90px;

    @media screen and (max-width: 635px) {
        flex-direction: column;
        width: 100%;
        margin-Top: 0px;

    }
}

.solution-main-wrapper {
    @include wrapper;
}

.solution-close {
    position: fixed;
    font-weight: 900;
    font-size: 32px;
    margin: 1rem;
    border: solid 2px $black;
    border-radius: 100px;
    height: 50px;
    width: 50px;
    background-color: white;
    color: white;
    right: 0;
    top: 80px;
    justify-content: center;
    align-items: center;
    text-align: center;
    display: flex;
    z-index: 999;

    & div {
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        width: 100%;
        height: 100%;
        line-height: 100%
    }

    &:hover {
        border: solid 2px black;
        background-color: $primaryBlue;
    }

    @media screen and (max-width: 635px) {
        height: 30px;
        width: 30px;
    }
}