.btn {

    transition-duration: 0.2s;
    
    &--main {

        background-color: $primaryBlue;
        color: #fff;
        height: 40px;
        line-height: 40px;
        padding: 0 30px 0 30px;
        margin-top: 20px;
        width: 230px;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
        outline: none;
        border: none;
        transition: all 0.2s ease-in-out;
        & a {
            text-decoration: none;
            color: #fff;
        }
        &:hover {
            transition: all 0.2s ease-in-out;
            color: #fff;
            background-color: $darkBlue;
        }
    }

    &--link{
        background-color: transparent;
        color:$primaryBlue;
        font-weight: 500;
        border:0px;
        font-Size: 16;

        &:hover{
            color:$darkBlue;
        }
    }

    &--link-wrapper{
        display:flex;
        justify-Content:center;
        margin: 1rem;
        padding:1rem;
    }

    &--demo {
        background-color: $primaryBlue;
        color: #fff;
        height: 40px;
        line-height: 40px;
        padding: 0 10px 0 10px;
        margin: 0 0 0 16px;
        margin-top: 20px;
        width: 190px;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
        outline: none;
        border: none;
        transition: all 0.2s ease-in-out;

        &:hover {
            transition: all 0.2s ease-in-out;
            color: #fff;
            background-color: $darkBlue;
        }

        @media screen and (max-width: 960px) {
            margin: 10px 0 40px 0;
        }
    }

    &--demo-about {
        background-color: $primaryBlue;
        color: #fff;
        height: 40px;
        line-height: 40px;
        padding: 0 10px 0 10px;
        margin-top: 20px;
        width: 190px;
        border-radius: 6px;
        font-size: 16px;
        font-weight: 500;
        text-decoration: none;
        cursor: pointer;
        outline: none;
        border: none;
        transition: all 0.2s ease-in-out;

        &:hover {
            transition: all 0.2s ease-in-out;
            color: #fff;
            background-color: $darkBlue;
        }

        @media screen and (max-width: 960px) {
            margin: 10px 0 40px 0;
        }
    }

    &--form-input {
        height: 40px;
        min-height: 40px;
        font-size: 18px;
        color: #fff;
        font-weight: 500;
        background-color: $primaryBlue;
        cursor: pointer;
        border: none;
        outline: none;
        border-radius: 6px;
        transition: all 0.2s ease-in-out;

        &:hover {
            transition: all 0.2s ease-in-out;
            background-color: $darkBlue;
        }

        @media screen and (max-width: 430px) {
            width: 80%;
            height: 56px;
            min-height: 56px;
            font-size: 16px;
            align-self: center;
        }

    }
    
    &--timeline-solution {

        height: 40px;
        padding: 0 40px;
        min-height: 40px;
        font-size: 18px;
        color: #fff;
        font-weight: 500;
        background-color: $primaryBlue;
        cursor: pointer;
        border: none;
        outline: none;
        border-radius: 6px;
        transition: all 0.2s ease-in-out;
        display: block;
        margin: auto;
        margin-top: 1rem;
    
        & a {
            text-decoration: none;
            color: #fff;
        }
        
        &:hover {
            transition: all 0.2s ease-in-out; 
            background-color: $darkBlue;
        }
    
        @media screen and (max-width: 430px) {
            width: 80%;
            height: 56px;
            min-height: 56px;
            font-size: 16px;
            align-self: center;
            margin-bottom: 40px;
        }
    
    }
}
