@font-face {
    font-family: 'logo-font';
    src: url(../../fonts/logo-font.ttf);
}
@import '../../sass/main.scss';


.navbar {
    background: #fff;
    z-index: 999;
    height: 80px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    -webkit-sticky: sticky;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    left: 0;
    box-shadow: 0 5px 10px rgba(20, 32, 50, 0.1);
}
  
.navbar-logo {
    color: #000;
  
    cursor: pointer;
    text-decoration: none;
    font-size: 32px;
    font-family: 'logo-font';
    margin-right: 150px;

    @media screen and (max-width: 1220px) {
        margin-right: 100px;
        
    }

    @media screen and (max-width:960px) {
        position: absolute;
    
        left: 0;
        transform: translate(25%, 20%);
    }
}

.hook-logo {
    height: 32px;
    width: 32px;
}

.blue {
    color: $primaryBlue;
    font-family: 'logo-font';
}
  
.nav-menu {
    display: grid;
    grid-template-columns: repeat(7, auto);
    list-style: none;
    text-align: center;
    width: auto;
    justify-content: end;
    padding-right: 20px;

    @media screen and (max-width: 960px){
        background-color: #fff;
        display: flex;
        flex-direction: column;
        width: 100%;
        // height: 90vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease; 
        
        &.active {
            left: 0;
            opacity: 1;
            transition: all 0.5s ease;
            z-index: 1;
            padding-right: 0px;
            padding-top: -40px;
        }
    }
}

.nav-right {
    margin-left: 170px;

    @media screen and (max-width: 1220px) {
        margin-left: 0;
    }
}
  
.nav-item {
    display: flex;
    align-items: center;
    height: 80px;

    @media screen and (max-width: 960px) {
        position: relative;
    }
}

.display-only-mobile {
    display: none;

    @media screen and (max-width: 960px) {
        display: block;
    }
}
  
.nav-links {
    color: #000;
    text-decoration: none;
    padding: 10px 16px;
    cursor: pointer;

    &:hover {
        color: $primaryBlue;
        transition: all 0.2s ease-out; 
    }

    &.active {
        color: $primaryBlue;
    }

    @media screen and (max-width: 960px) {
        text-align: center;
        padding: 32px;
        width: 100%;
        display: table;

        &:hover {
            color: $primaryBlue;
        }
    }
}
  
.fa-bars {
    color: #000;
}
  
.nav-links-mobile {
    display: none;

    @media screen and (max-width: 960px) {
        display: block;
        text-align: center;
        padding: 24px;
        margin: 32px auto;
        border-radius: 4px;
        width: 80%;
        background: #fff;
        text-decoration: none;
        color: #000;
        font-size: 24px;

        &:hover {
            background: #fff;
            color: $primaryBlue;
            transition: 250ms;
        }
    }
}
  
.menu-icon {
    display: none;

    @media screen and (max-width: 960px) {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 50%);
        font-size: 32px;
        cursor: pointer;
        width: 50px;
        height: 50px;
    }
}
  
@media screen and (max-width: 960px) {
  
    .fa-times {
      color: #000;
      font-size: 32px;
    }

    .display-none-mobile {
        display: none;
    }
}