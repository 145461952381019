@import '../../../../sass/main.scss';

.custom-overview-container {
    height: auto;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.custom-overview-wrapper {
    height: 440px;
    width: 1320px;
    margin: 80px 0 80px 0;
    display: flex; 
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 1340px){
        width: 90%;
        height: auto;
    }

    @media screen and (max-width: 1250px) {
        flex-direction: column;
    }
}

.custom-overview-image {
    width: 640px;
    height: auto;

    @media screen and (max-width: 1340px){
        width: 100%;
        height: auto;
    }
}

.custom-overview-content {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 60px;

    a {
        text-decoration: none;
        justify-self: left;

        @media screen and (max-width: 1250px) {
            justify-self: center;
        }
    }

    @media screen and (max-width: 1340px) {
        width: 50%;
        margin-left: 40px;
    }

    @media screen and (max-width: 1250px) {
        width: 90%;
        text-align: center;
        margin-left: 0;
        margin-top: 20px;
        align-items: center;
        justify-content: center;
    }
}

.custom-overview-heading {
    margin-bottom: 20px;
}

.custom-overview-text p {
    font-size: 20px;
    line-height: 1.5;

    @media screen and (max-width: 620px) {
        font-size: 18px;
    }

    @media screen and (max-width: 400px) {
         font-size: 16px;
    }
}

.custom-overview-cards {
    display: grid;
    grid-gap: 40px;
    max-width: 600px;
    margin-top: 20px;
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 1fr);

    @media screen and (max-width: 1340px){
        width: 90%;
        height: auto;
    }

    @media screen and (max-width: 1250px) {
        width: 90vw;
        justify-self: center;
    }

    @media screen and (max-width: 800px) {
        grid-gap: 20px;
    }

    @media screen and (max-width: 500px) {
        width: 100%;
    }
}

.custom-overview-card {
    width: 280px;
    height: 100px;
    border-radius: 6px;
    background-color: $lighterBlue;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
        transition: all 0.2s ease-in-out;
        background-color: #dceeff; 
    }

    @media screen and (max-width: 1250px) {
        justify-self: center;
    }
}

.custom-overview-icon {
    height: 50px;
    width: 50px;
    margin-right: 10px;

    @media screen and (max-width: 650px) {
        img {
            height: 40px;
            width: 40px;
            margin-right: 0;
            margin-left: 6px;
        }
    }

    @media screen and (max-width: 500px) {
        img {
            height: 50px;
            width: 50px;
            margin-right: 10px; 
            margin-left: 0;
        }
    }
}

.custom-overview-span {
    color: #000;
    font-size: 18px;
    max-width: 140px;
    text-align: center;
    font-weight: 500;

    @media screen and (max-width: 620px) {
        font-size: 16px;
    }

    @media screen and (max-width: 400px) {
         font-size: 14px;
    }
}